.navbar-header {
  height: 14em;
  background-color: #3D7DA2;
}

.navbar-brand img {
  width: 95%;
}

ul.nav.navbar-nav.navbar-right > li a {
  color: white;
  font-family: 'open sans', sans-serif;
  background-color: #397598;
  margin: 2em 1.5em;
}

body > nav > div > div.navbar-header > button {
  background-color: #3D7DA2;
}

.navbar-default .navbar-toggle span.icon-bar {
  background-color: #FFFFFF;
}

body > nav > div > div.navbar-header > button.navbar-toggle:hover {
  background-color: #3D7DA2;
}

.carousel-divider {
  background-color: #FFFA9F;
  height: 2em;
}

.navbar-default.navbar-toggle:focus, .navbar-default.navbar-toggle:hover {
  background-color: red;
}

.carousel-caption {
  visibility: hidden;
}

a.right.carousel-control {
  opacity: 0;
}

a.right.carousel-control:hover {
  opacity: .1;
  color: red;
}

a.left.carousel-control {
  opacity: 0;
}

a.left.carousel-control:hover {
  opacity: .1;
  color: red;
}

nav.navbar.navbar-default {
  margin-bottom: 0;
  border: 0;
}

.navbar-collapse {
  background-color: #3D7DA2;
}

.main-blog {
  padding-top: 2em;
}

.main-blog a:hover {
  text-decoration: none;
}

.main-blog .star-icon {
  margin: 0 auto;
  color: #F0C229;
  background-color: #131C28;
  height: 4em;
  width: 4em;
  border-radius: 50%;
}

.main-blog .star-icon i {
  padding: .2em;
}

.main-blog .blog-title {
  color: #D01814;
  font-family: 'Roboto Slab', serif;
  font-size: 30px;
  margin: .5em;
}

.main-blog .blog-date {
  display: inline-block;
  color: #D01814;
  margin: 1em;
}

.main-blog .blog-date i {
  color: #ABABAB;
}

.main-blog .leave-comment-home {
  color: #D01814;
}

.main-blog .leave-comment-home i {
  color: #ABABAB;
}

.main-blog a section {
  margin: 1em;
  line-height: 2em;
  color: #6C6C6C;
  text-decoration: none;
}

section.main-blog-posts div.pagination {
  padding-left: 1.5em;
  margin-right: 2em;
  float: right;
}

section.main-blog-posts div.pagination a {
  color: #D01814;
  padding: .5em;
  border: solid #C1C1C1 .1px;
  border-radius: 5px;
  text-decoration: none;
}

.search-box {
  border: solid 1px #C1C1C1;
  border-radius: 5px;
  margin: 6em 1em 1em;
  padding: 1em;
}

.search-box section a {
  text-decoration: none;
}

.search-box div.wrapper a:hover {
  text-decoration: none;
}

.search-box h2 {
  color: #3E7EA2;
  font-size: 1.1em;
  font-family: 'Roboto Slab', serif;
  padding: 1em;
}

.search-box form {
  color: #757575;
  margin: 1em;
  font-weight: bold;
}

.search-box form input {
  display: block;
  border: solid 1px #C1C1C1;
  border-radius: 5px;
  margin-top: 1em;
  padding: .5em;
  width: 100%;
}

.search-box form button {
  background-color: #EE150F;
  color: #FFFFFF;
  border-radius: 5px;
  border: none;
  margin-top: 1em;
  text-decoration: none;
  padding: .5em;
}

.individual-post {
  overflow: hidden;
  margin-bottom: 1em;
}

.individual-post .post-icon {
  float: left;
  width: 25%;
  margin: 1em;
}

.individual-post .star-icon {
  margin-left: 1em;
  color: #F0C229;
  background-color: #131C28;
  height: 4em;
  width: 4em;
  border-radius: 50%;
}

.individual-post .star-icon i {
  padding: .2em;
}

.individual-post .graph-icon {
  margin: 0 auto;
  padding-left: 1em;
}

.popular-title-post {
  width: 64%;
  float: right;
  color: #D01814;
  margin-top: 1em;
}

.popular-title-post .popular-post-date {
  color: #C1C1C1;
}

footer .post-footer {
  margin-top: 4em;
  padding-top: 3em;
  background-color: #3D7DA2;
  padding-bottom: .5em;
}

footer .post-footer div.individual-post .popular-title-post {
  width: 100%;
  float: left;
  color: white;
  padding-left: 2em;
}

footer .post-footer div.individual-post .popular-title-post .popular-post-date {
  color: white;
  font-size: 13px;
}

footer div.footer {
  background-color: #FFFA9F;
  width: 100%;
  padding-top: 1em;
  padding-bottom: 1em;
  text-align: center;
}

footer div.footer a {
  margin: 1em;
  color: #3F3F39;
  font-size: .9em;
  text-decoration: none;
}

footer div.footer h4 {
  font-size: .9em;
  text-align: center;
}

div.page-header {
  border-bottom: none;
}

div.page-header h1 {
  padding-left: 1em;
}

div.email-policy-main {
  padding: 1em;
  color: #6B6B6B;
}

div.email-policy-main .post-icon {
  width: 25%;
  margin: 0 auto;
  padding-top: 1em;
}

div.email-policy-main .post-icon .star-icon {
  margin-left: 1em;
  color: #F0C229;
  background-color: #131C28;
  height: 4em;
  width: 4em;
  border-radius: 50%;
}

div.email-policy-main .post-icon .star-icon i {
  padding: .2em;
}

div.email-policy-main h2 {
  padding-top: 10px;
  color: #3D7DA2;
  font-family: 'roboto slab', serif;
}

div.email-policy-main #contentsec div.text-box div.alert-msg a {
  color: red;
}

div.email-policy-main #contentsec div.text-box p a {
  color: red;
}

div.privacy-policy-main {
  padding: 1em;
  color: #6B6B6B;
}

div.privacy-policy-main .post-icon {
  width: 25%;
  margin: 0 auto;
  padding-top: 1em;
}

div.privacy-policy-main .post-icon .star-icon {
  margin-left: 1em;
  color: #F0C229;
  background-color: #131C28;
  height: 4em;
  width: 4em;
  border-radius: 50%;
}

div.privacy-policy-main .post-icon .star-icon i {
  padding: .2em;
}

div.privacy-policy-main h2 {
  padding-top: 10px;
  color: #3D7DA2;
  font-family: 'roboto slab', serif;
}

div.privacy-policy-main #contentsec h3 {
  color: #3D7DA2;
}

div.privacy-policy-main #contentsec div:nth-child(31) h4 {
  color: #3D7DA2;
}

div.privacy-policy-main #contentsec div:nth-child(31) p > a {
  color: #EE150F;
}

section.terms-of-use {
  padding: 1em;
  color: #6B6B6B;
}

section.terms-of-use .post-icon {
  width: 25%;
  margin: 0 auto;
  padding-top: 1em;
}

section.terms-of-use .post-icon .star-icon {
  margin-left: 1em;
  color: #F0C229;
  background-color: #131C28;
  height: 4em;
  width: 4em;
  border-radius: 50%;
}

section.terms-of-use .post-icon .star-icon i {
  padding: .2em;
}

section.terms-of-use h2 {
  padding-top: 10px;
  color: #3D7DA2;
  font-family: 'roboto slab', serif;
}

section.terms-of-use #contentsec h3 {
  color: #3D7DA2;
  font-family: 'roboto slab', serif;
}

section.terms-of-use #contentsec div:nth-child(47) h4 {
  color: #3D7DA2;
}

div.unsubscribe-form {
  padding: 1em;
  color: #6B6B6B;
}

div.unsubscribe-form .post-icon {
  width: 25%;
  margin: 0 auto;
  padding-top: 1em;
}

div.unsubscribe-form .post-icon .star-icon {
  margin-left: 1em;
  color: #F0C229;
  background-color: #131C28;
  height: 4em;
  width: 4em;
  border-radius: 50%;
}

div.unsubscribe-form .post-icon .star-icon i {
  padding: .2em;
}

div.unsubscribe-form h2 {
  padding-top: 10px;
  color: #3D7DA2;
  font-family: 'roboto slab', serif;
}

div.unsubscribe-form #contentsec form.unsubscribe input#EmailAddress {
  border: solid 1px #DADADA;
  padding: .5em;
  width: 80%;
  border-radius: 6px;
}

div.unsubscribe-form #contentsec form.unsubscribe input.button {
  background-color: #EE150F;
  color: white;
  border-radius: 6px;
  padding: .8em;
  border: none;
}

div.signup {
  padding: 1em;
  color: #6B6B6B;
}

div.signup .post-icon {
  width: 25%;
  margin: 0 auto;
  padding-top: 1em;
}

div.signup .post-icon .star-icon {
  margin-left: 1em;
  color: #F0C229;
  background-color: #131C28;
  height: 4em;
  width: 4em;
  border-radius: 50%;
}

div.signup .post-icon .star-icon i {
  padding: .2em;
}

div.signup h2 {
  padding-top: 10px;
  color: #3D7DA2;
  font-family: 'roboto slab', serif;
}

div.signup #contentsec form.signup input {
  border: solid 1px #DADADA;
  padding: .5em;
  width: 90%;
  border-radius: 6px;
}

div.signup #contentsec form.signup p.check input.terms {
  width: 10%;
}

div.signup #contentsec form.signup p.check a {
  color: #cd0808;
}

div.signup #contentsec form.signup .button {
  background-color: #EF1E14;
  color: white;
}

section.blog-page {
  padding: 1.5em;
  color: #6B6B6B;
}

section.blog-page .post-icon {
  width: 25%;
  margin: 0 auto;
  padding-top: 1em;
}

section.blog-page .post-icon .star-icon {
  margin-left: 1em;
  color: #F0C229;
  background-color: #131C28;
  height: 4em;
  width: 4em;
  border-radius: 50%;
}

section.blog-page .post-icon .star-icon i {
  padding: .2em;
}

section.blog-page div.blog-date {
  color: #D01814;
  margin-top: 1em;
  margin-bottom: 1em;
}

section.blog-page div.blog-date i.fa-calendar {
  color: #6B6B6B;
}

section.blog-page h2.title {
  color: #3D7DA2;
  font-family: 'roboto slab', serif;
}

div.contact {
  padding: 1em;
  color: #6B6B6B;
}

div.contact .post-icon {
  width: 25%;
  margin: 0 auto;
  padding-top: 1em;
}

div.contact .post-icon .star-icon {
  margin-left: 1em;
  color: #F0C229;
  background-color: #131C28;
  height: 4em;
  width: 4em;
  border-radius: 50%;
}

div.contact .post-icon .star-icon i {
  padding: .2em;
}

div.contact h2 {
  padding-top: 10px;
  color: #3D7DA2;
  font-family: 'roboto slab', serif;
}

div.contact h4 {
  color: #3D7DA2;
}

div.contact p {
  width: 65%;
}

div.contact form > table {
  width: 150%;
}

div.contact form > table td > div.field > p input#name {
  width: 100%;
  border: solid 1px #ECECEC;
  padding: .5em;
  border-radius: 5px;
}

div.contact form > table td > div.field > p input#email {
  width: 100%;
  border: solid 1px #ECECEC;
  padding: .5em;
  border-radius: 5px;
}

div.contact form > table td > div.field > p input#subject {
  width: 100%;
  border: solid 1px #ECECEC;
  padding: .5em;
  border-radius: 5px;
}

div.contact form > table td > div.field > p textarea#message {
  width: 100%;
  height: 10em;
  border: solid 1px #ECECEC;
  padding: .5em;
  border-radius: 5px;
}

div.contact form > table td > div.field > p input#send.button.is-primary {
  color: white;
  background-color: red;
  border: none;
  padding: .5em;
  border-radius: 5px;
}

div.page-header h1.title {
  color: #3C7CA2;
  margin-left: -2em;
}

div.unsubscribe-success {
  padding: 1em;
  color: #6B6B6B;
}

div.unsubscribe-success .post-icon {
  width: 25%;
  margin: 0 auto;
  padding-top: 1em;
}

div.unsubscribe-success .post-icon .star-icon {
  margin-left: 1em;
  color: #F0C229;
  background-color: #131C28;
  height: 4em;
  width: 4em;
  border-radius: 50%;
}

div.unsubscribe-success .post-icon .star-icon i {
  padding: .2em;
}

div.unsubscribe-success h2 {
  padding-top: 10px;
  color: #3D7DA2;
  font-family: 'roboto slab', serif;
}

div.unsubscribe-success div.Body div a {
  color: #D01814;
}

div.unsubscribe-success form div > input {
  border: solid 1px #ECECEC;
  border-radius: 6px;
}

div.unsubscribe-success form div.Submit > input {
  color: white;
  background-color: #EE150F;
  border-radius: 6px;
}

div.thankyou {
  padding: 2em;
  color: #6B6B6B;
}

div.thankyou .post-icon {
  width: 25%;
  margin: 0 auto;
  padding-top: 1em;
}

div.thankyou .post-icon .star-icon {
  margin-left: 1em;
  color: #F0C229;
  background-color: #131C28;
  height: 4em;
  width: 4em;
  border-radius: 50%;
}

div.thankyou .post-icon .star-icon i {
  padding: .2em;
}

.cd-top {
  color: white;
  text-align: center;
  padding-top: .5em;
  display: inline-block;
  border-radius: 10%;
  height: 30px;
  width: 30px;
  position: fixed;
  bottom: 40px;
  right: 22px;
  overflow: hidden;
  background-color: #BE310C;
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s 0s, visibility 0s .3s;
}

.cd-top.cd-is-visible, .no-touch .cd-top:hover {
  transition: opacity .3s 0s, visibility 0s 0s;
}

.cd-top.cd-is-visible {
  visibility: visible;
  opacity: 1;
  color: white;
}

.cd-top.cd-fade-out {
  color: white;
}

.no-touch .cd-top:hover {
  opacity: 1;
}

@media (min-width: 768px) {
  .carousel {
    height: 24em;
  }
  .carousel-inner {
    height: 24em;
  }
  .carousel-inner .item > img.carousel-image {
    position: absolute;
    left: 4px;
    top: -180px;
  }
  div.container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  ul.nav.navbar-nav.navbar-right {
    margin-right: 2em;
  }
  ul.nav.navbar-nav.navbar-right li a {
    font-size: 12px;
    margin: 0;
    padding: 20px 2em;
  }
  .search-box {
    margin: 7em 1.5em 1.5em;
    padding: 1em;
  }
  .search-box h2 {
    padding: 1em;
  }
  .search-box form input {
    margin-top: 1em;
    padding: .5em;
    width: 60%;
  }
  .search-box form button {
    margin-top: 1em;
    padding: .5em;
    display: inline-block;
  }
  .search-box form div.popular-title-post {
    float: none;
  }
  .search-box form div.post-icon {
    width: 0%;
    margin-left: 4em;
  }
  footer .post-footer {
    padding-left: 1em;
    padding-top: .5em;
    padding-bottom: .5em;
  }
  footer .post-footer a {
    display: inline-block;
    width: 30%;
    vertical-align: top;
  }
  footer div.footer {
    padding-top: .5em;
  }
  footer div.footer h4 {
    margin: 0;
    padding-top: .2em;
  }
}

@media (min-width: 769px) {
  .carousel-caption {
    visibility: visible;
    top: 130px;
  }
  .carousel-caption a {
    color: white;
  }
  .carousel-caption a h3 {
    background-color: rgba(255, 0, 0, 0.7);
    font-family: 'Roboto Slab', serif;
    font-weight: lighter;
    padding: .5em;
    text-decoration: none;
  }
  .carousel-caption a p {
    background-color: rgba(96, 95, 95, 0.8);
    padding: 1em;
    margin-top: -10px;
    text-decoration: none;
  }
  .carousel-caption a:hover {
    text-decoration: none;
    color: white;
  }
}

@media (min-width: 900px) {
  .sidebar-blog-posts {
    width: 85%;
    margin: 0 auto;
  }
  .individual-post .post-icon {
    width: 10%;
  }
  .popular-title-post {
    float: none;
    width: auto;
  }
}

@media (min-width: 967px) {
  ul.nav.navbar-nav.navbar-right {
    margin-top: 10em;
  }
}

@media (min-width: 992px) {
  section.main-blog-posts {
    display: inline-block;
    width: 60%;
    float: left;
  }
  div.main-blog {
    width: 60%;
  }
  .sidebar-blog-posts {
    display: inline-block;
    width: 40%;
  }
  .sidebar-blog-posts div.search-box form input {
    width: 100%;
  }
  .sidebar-blog-posts div.search-box div.individual-post .post-icon {
    width: 25%;
  }
  .sidebar-blog-posts div.search-box div.popular-title-post {
    margin-right: .5em;
  }
  div.email-policy-main {
    width: 60%;
    float: left;
  }
  div.privacy-policy-main {
    width: 60%;
    float: left;
  }
  section.terms-of-use {
    width: 60%;
    float: left;
  }
  div.unsubscribe-form {
    width: 60%;
    float: left;
  }
  div.signup {
    width: 60%;
    float: left;
  }
  section.blog-page {
    width: 60%;
    float: left;
  }
  div.thankyou {
    width: 60%;
    float: left;
  }
  div.contact {
    width: 60%;
    float: left;
  }
  div.page-header {
    width: 60%;
    float: left;
  }
  div.unsubscribe-success {
    width: 60%;
    float: left;
  }
  section.news {
    float: left;
    margin-bottom: 3em;
    width: 60%;
  }
  section.news div.main-blog {
    width: 100%;
  }
  section.post-footer {
    margin-top: 14em;
  }
}

@media (min-width: 1200px) {
  ul.nav.navbar-nav.navbar-right {
    margin-top: 6em;
  }
  .carousel {
    height: 30em;
  }
  .carousel-inner {
    height: 30em;
  }
  .carousel-inner .item > img.carousel-image {
    top: -280px;
  }
  .carousel-caption {
    top: 215px;
    width: 60%;
    margin-left: -215px;
  }
}

@media (min-width: 1600px) {
  .navbar-brand > img {
    padding-left: 12em;
  }
  ul.nav.navbar-nav.navbar-right {
    padding-right: 12em;
  }
  .carousel {
    padding-right: 7em;
    padding-left: 7em;
  }
  .carousel-inner {
    padding-right: 7em;
    padding-left: 7em;
  }
  .carousel-inner .item > img.carousel-image {
    padding-right: 7em;
    padding-left: 7em;
    top: -227px;
  }
  .carousel-caption {
    width: 45%;
    top: 178px;
  }
  div.main-blog {
    padding-left: 12em;
    width: 80%;
  }
  div.search-box {
    width: 65%;
  }
  div.contact {
    padding-left: 22em;
    width: 50%;
  }
  div.thankyou {
    padding-left: 19em;
    padding-top: 4em;
    width: 60%;
  }
  div.email-policy-main {
    padding-left: 20em;
    width: 50%;
  }
  div.unsubscribe-form {
    padding-left: 20em;
    width: 50%;
  }
  div.unsubscribe-form #contentsec p {
    padding-right: 1em;
  }
  div.page-header h1 {
    padding-left: 8em;
  }
  section#news div.main-blog {
    padding-left: 1em;
  }
  div.signup {
    padding-left: 18em;
    width: 50%;
  }
  div.signup #contentsec p.check {
    padding-right: 4em;
  }
  section.blog-page {
    padding-left: 16em;
    width: 60%;
  }
  footer .post-footer a {
    width: 24%;
  }
  footer .post-footer {
    padding-left: 23em;
  }
}
